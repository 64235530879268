<template>
  <v-card>
    <v-card-title>Datos del Feriado</v-card-title>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="">
      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
              :items="tipos"
              item-text="nombre"
              item-value="id"
              label="Tipo Feriado"
              v-model="tipo"
            ></v-select>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="nombre"
              :counter="150"
              label="Nombre"
              required
              :rules="nombreRules"
              @input="nombre = nombre.toUpperCase()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              ref="menuFecha"
              v-model="menuFecha"
              :close-on-content-click="false"
              :return-value.sync="fecha"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha"
                  label="Fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  required
                  :rules="selectRules"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fecha" no-title scrollable locale="es">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuFecha = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menuFecha.save(fecha)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-switch
              v-model="repite_anual"
              :label="repite_anual ? 'Repite Anual: Sí' : 'Repite Anual: No'"
            ></v-switch>
          </v-col>
        </v-row>

        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValidity"
            color="primary"
            type="submit"
            :loading="loadingSave"
            @click="onSubmit()"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/feriados"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import PresentismoService from "../services/PresentismoService";

export default {
  name: "FeriadoForm",
  components: {},

  data() {
    return {
      tipos: [],
      tipo: 1,
      formValidity: false,
      nombre: "",
      error: false,
      menuFecha: false,
      fecha: null,
      repite_anual: false,
      loadingSave: false,
      nombreRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 150 ||
          "La longuitud del campo nombre debe ser menor o igual a 150 caracteres.",
      ],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
    };
  },
  props: {
    feriado: {
      type: Object,
      required: false,
    },
  },
  created() {
    PresentismoService.getTiposFeriado()
      .then((response) => {
        this.tipos = response.data;
      })
      .catch((error) => console.log(error));

    if (this.feriado) {
      this.tipo = this.feriado.tipo;
      this.nombre = this.feriado.nombre;
      this.fecha = this.feriado.fecha;
      this.repite_anual = this.feriado.repite_anual == "Sí" ? true : false;
    }
  },

  methods: {
    onSubmit() {
      this.loadingSave = true;

      let feriadoInstance = {
        tipo: this.tipo,
        nombre: this.nombre,
        fecha: this.fecha,
        repite_anual: this.repite_anual,
      };

      if (this.feriado) {
        feriadoInstance.id = this.feriado.id;
        PresentismoService.putFeriado(feriadoInstance)
          .then((response) => {
            this.$router.push({
              name: "FeriadoDetalle",
              params: { id: feriadoInstance.id },
            });
          })
          .catch((error) => {
            this.error = error.response.data;
            this.loadingSave = false;
          });
      } else {
        PresentismoService.postFeriado(feriadoInstance)
          .then((response) => {
            this.$router.push({
              name: "Feriados",
            });
          })
          .catch((error) => {
            this.error = error;
            this.loadingSave = false;
          });
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
